<script setup lang="ts">
import { BasePopup } from '@qic/ui-components';
import { computed, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import NeedHelpButton from './ui/NeedHelpButton/NeedHelpButton.vue';
import WhatsAppButton from '@/features/WhatsAppButton';
import HotlineButton from '@/features/HotlineButton';
import { useGoogleAnalytics } from '@/shared/composables/useGoogleAnalytics';
import router from '@/app/router';
import { useFlowStore } from '@/shared/composables';
const { locale } = useI18n();
const gaService = useGoogleAnalytics();
const isRenew = inject('isRenew') as boolean;

const popupShown = ref<boolean>(false);
const flowStore = useFlowStore();

const isRtl = computed(() => locale.value === 'ar');

const onShowHelpPopup = () => {
  popupShown.value = true;
  gaService.clickContactUs(
    isRenew,
    router.currentRoute.value?.name as string,
    flowStore.policyNumber,
    flowStore.quoteNumber,
  );
};
const onClose = () => {
  popupShown.value = false;
};
</script>

<template>
  <div class="flow__footer-widget">
    <NeedHelpButton @click="onShowHelpPopup" />
    <BasePopup
      v-if="popupShown"
      :is-rtl="isRtl"
      :use-button="false"
      class="help-popup"
      @close="onClose"
    >
      <div class="need-help">
        <h3 class="need-help__title">{{ $t('help_popup.title') }}</h3>

        <div class="need-help__content">
          <p class="need-help__desc">{{ $t('tpl_flow.contact_us.text_call_us') }}</p>
          <WhatsAppButton />
          <HotlineButton />
        </div>
      </div>
    </BasePopup>
  </div>
</template>

<style lang="scss" scoped>
@import 'FooterWidget';
</style>
