import { defineStore } from 'pinia';

import type { PolicyHolderInfo, LicenseInfo } from '@/shared/api/FlowService';

import { type PolicyHolder } from '../lib/PolicyHolder.types';

export const usePolicyHolderStore = defineStore('PolicyHolderEntity', {
  state: (): PolicyHolder => ({
    dateOfBirth: null,
    drivingExperienceOman: null,
    email: '',
    familyName: '',
    firstName: '',
    licenseNumber: '',
    phone: '',
    isDateOfBirthEditable: true,
    isDrivingExpEditable: true,
    isLicenseCorrect: null,
    licenseErrorName: null,
    srNo: 1,
  }),
  getters: {
    transId: (state) => state.licenseNumber.substring(state.licenseNumber.length - 4),
    getDrivingExperienceOman: (state) =>
      state.drivingExperienceOman?.toString() ? state.drivingExperienceOman.toString() : null,
  },
  actions: {
    setLicenseInfo(payload: LicenseInfo) {
      this.setLicenseErrorName(null);

      const experienceExist = payload.drivingExp !== null && payload.drivingExp >= 0;

      this.setLicenseNumber(payload.licenseNumber);
      this.setIsLicenseCorrect(!!payload.dateOfBirth);

      if (payload.dateOfBirth) {
        this.setDateOfBirth(payload.dateOfBirth);
        this.setIsDateOfBirthEditable(false);
      } else {
        this.setDateOfBirth(null);
        this.setIsDateOfBirthEditable(true);
      }

      if (experienceExist) {
        this.setDrivingExperienceOman(payload.drivingExp);
        this.setIsDrivingExpEditable(false);
      } else {
        this.setDrivingExperienceOman(null);
        this.setIsDrivingExpEditable(true);
      }
    },

    setLicenseError(errorCode: string) {
      this.setLicenseErrorName(errorCode);
      this.resetLicenseInfo();
    },

    setLicenseNumber(licenseNumber: string) {
      this.licenseNumber = licenseNumber;
    },

    setDrivingExperienceOman(drivingExperienceOman: Nullable<number>) {
      this.drivingExperienceOman = drivingExperienceOman;
    },

    setFirstName(firstName: string) {
      this.firstName = firstName;
    },

    setFamilyName(familyName: string) {
      this.familyName = familyName;
    },

    setEmail(email: string) {
      this.email = email;
    },
    setIsDrivingExpEditable(value: boolean) {
      this.isDrivingExpEditable = value;
    },

    setPhone(phone: string) {
      this.phone = phone;
    },

    setDateOfBirth(dateOfBirth: Nullable<Date>) {
      this.dateOfBirth = dateOfBirth;
    },

    setLicenseErrorName(value: Nullable<string>) {
      this.licenseErrorName = value;
    },

    setIsDateOfBirthEditable(value: boolean) {
      this.isDateOfBirthEditable = value;
    },

    setPolicyHolderInformation(
      payload: Omit<PolicyHolderInfo, 'dateOfBirth' | 'drivingExperienceOman'>,
    ) {
      this.$patch(payload);
    },

    setIsLicenseCorrect(payload: Nullable<boolean>) {
      this.isLicenseCorrect = payload;
    },

    resetLicenseInfo() {
      this.setDateOfBirth(null);
      this.setDrivingExperienceOman(null);
      this.setIsDateOfBirthEditable(true);
      this.setIsDrivingExpEditable(true);
      this.setIsLicenseCorrect(false);
    },
  },
});
