import { defineStore } from 'pinia';
import type { FlowStoreType, QuoteType } from '@/shared/types/flowStore.types';

import { useApi } from './useApi';
import { regionNames } from '../constants/regionNamesMapping';

export const useFlowStore = defineStore('FlowStore', {
  state: (): FlowStoreType => ({
    startPolicy: new Date(),
    quotes: [],
    quoteNumber: '',
    geoAreaName: '',
    quoteNameCode: '',
    geoAreaCode: '',
    policyNumber: '',
    policyStatus: '',
    productCode: '',

    // AB testing
    ABDistribution: [],
  }),
  getters: {
    getChosenScheme: (state) =>
      state.quotes
        .find((quote) => quote.quoteno === state.quoteNumber)
        ?.schemes.find((scheme) => scheme.productcode === state.productCode),
  },
  actions: {
    async save(params: any) {
      await useApi().flowService.sendFormData(params);
    },

    setStartPolicyDate(date: Date) {
      this.startPolicy = date;
    },

    setQuotes(quotes: QuoteType[]) {
      this.quotes = quotes;
    },

    setProductCode(productCode: string) {
      this.productCode = productCode;
    },

    async fetchQuotes(geoArea: string, locale: string) {
      const quotes = await useApi().flowService.fetchQuotes({ geoArea, locale });

      this.setQuotes(quotes);
    },

    async updateQuote(quoteNumber: string) {
      await useApi().flowService.updateQuote({ quoteNo: quoteNumber });
    },

    setQuoteNumber(quoteNumber: string) {
      this.quoteNumber = quoteNumber;
    },

    setGeoAreaName(geoAreaName: string) {
      this.geoAreaName = geoAreaName;
    },

    setPolicyStatus(policyStatus: string) {
      this.policyStatus = policyStatus;
    },

    setPolicyNumber(policyNumber: string) {
      this.policyNumber = policyNumber;
    },

    setQuoteNameCode(code: string) {
      this.quoteNameCode = code;
    },

    setGeoArea(code: string) {
      this.geoAreaCode = code;
      this.geoAreaName = regionNames[code];
    },
  },
});
