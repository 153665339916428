import { ACTIVE_VEHICLE_TYPES } from './Car.constants';
// TODO: fix foreign dependency
import type { CarDescription } from '@/shared/api/FlowService';

export const checkValidBodyCode = (code: string) => ACTIVE_VEHICLE_TYPES.includes(code);

export const getUniqueListOf = (cars: CarDescription[], carProperty: keyof CarDescription) => {
  const uniqueValues = new Set();
  for (const car of cars) {
    uniqueValues.add(car[carProperty]);
  }
  return Array.from(uniqueValues) as (typeof carProperty)[];
};
