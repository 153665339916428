export interface Purchase {
  policyId: string;
  quoteNumber: string;
  addonsNumber: number;
  totalPrice: number;
  addonsPrice: number[];
  listOfAddons: string[];
  productCode: string;
}

export enum TFlowType {
  MANUAL = 'manual_flow',
  RECOGNIZED_PLATE_NUMBER = 'recognized_plate_number',
  UNRECOGNIZED_PLATE_NUMBER = 'unrecognized_plate_number',
  RECOGNIZED_CHASSIS_NUMBER = 'recognized_chassis_number',
  UNRECOGNIZED_CHASSIS_NUMBER = 'unrecognized_chassis_number',
  RECOGNIZED_LICENSE = 'recognized_license_number',
  UNRECOGNIZED_LICENSE = 'unrecognized_license_number',
}

export enum productCodes {
  OD = '0110',
  TPL = '0100',
}
